<template>
  <CommonPages id="detail" classs="detail ">
    <div :class="`cover-banner cover-banner-${deviceType} cover-banner-${domainConfig.BGNUM}`" />
    <section class="detail-container">
      <div class="page-head-row">
        <div class="page-head">
          {{ domainConfig.IAmTitle }}
        </div>
        <div class="page-head-slish" />
        <div v-if="wikiInfo" class="page-head-sub-head">
          {{ wikiInfo.summary }}
        </div>
      </div>

      <AdComponent v-if="deviceType == 'mobile'" ref="ads-detail-1"
        class="ads-insert pc_padding padding-horizon-24" :ads="adsensConfig.detail_1"
        :showDebug="showDebug" />

      <div class="common-body-container">
        <div class="common-content-row">
          <img v-if="wikiInfo" :src="wikiInfo.imgUrl" class="common-content-img" alt="">
          <div v-if="wikiInfo" class="common-content-title">
            {{ wikiInfo.title }}
          </div>
          <div v-if="wikiInfo" class="common-content" v-html="wikiInfo.description" />
          <div class="common-slish" />

          <AdComponent ref="ads-detail-2" class="ads-insert pc_padding "
            :ads="adsensConfig.detail_2" :showDebug="showDebug" />

          <div class="discover-container">
            <div class="discover-title">
              Discover More
            </div>
            <div class="discover-row">
              <template v-for="(data,i) in wikiList">
                <div :key="`list_item_${i}`" class="discover-item"
                  @click="chooseWiki(data.id,data.title,data.category)">
                  <div class="discover-img">
                    <img :src="data.thumbnailImgUrl" alt="">
                  </div>
                  <div class="discover-item-cotext">
                    <div class="discover-title">
                      {{ data.title }}
                    </div>
                    <div class="discover-btn-row">
                      Read More <img src="@/assets/icon-arrow.png" alt="">
                    </div>
                  </div>
                </div>
                <AdComponent v-if="shouldInsertAd(i) !== -1 && deviceType == 'mobile'"
                  :key="`ad_${i}`" :ref="'ads-detail-card' + i" class="ads-insert  "
                  :ads="adsensConfig?.detail_recommand?.[shouldInsertAd(i)]"
                  :showDebug="showDebug" />
              </template>
            </div>
          </div>
        </div>
        <div class="home-tags-row">
          <div class="tags-title">
            Tags
          </div>
          <div class="tag-row">
            <div v-for="(data,i) in tagList" :key="`tag_${i}`" class="tags-item cursor"
              :class="{'active':activeTag == data}" @click="chooseTag(data)">
              {{ data }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/pc_common.vue'

import '@/css/home.scss'

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      wikiList: [],
      tagList: [],

      page: 1,   // 当前页数
      hasNext: false,
      activeTag: null,
      wikiInfo: null,

      ////////
      activeTitle: '',
      activeId: null
    };
  },
  metaInfo () {
    return {
      title: `${this.activeTitle.replace(/-/g, ' ')} | ${this.domainConfig['meta'].title}`,
      meta: [
        {
          name: 'description',
          content: `${this.domainConfig['meta'].des}`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ])
  },
  created () {
    const { id, title, cat } = this.$route.params
    console.log(`id:${id} cat:${cat}`);
    this.activeTitle = title.replace(/-/g, ' ');
    this.activeId = id
    this.activeTag = cat.replace(/-/g, ' ');
    console.log(this.activeTag);
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    console.log(this.domainConfig['type']);
    this.getTagList()
    this.getWiki()
    await this.getWikiList()
    this.loadAdSenseScript();  // 重新加载广告脚本
    this.watchForIframeInsertion()

    // 使用防抖包装滚动事件
    this.onScroll = this.debounce(this.onScroll.bind(this), 300);
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll);
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    //客户端监听
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.setupIframeTracking(); // 在检测到广告 iframe 插入后，调用 setupIframeTracking
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    setupIframeTracking () {
      // let _this = this;
      console.log('ready to track iframe');
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');

          if (adContainer) {
            // 获取父级 ins 元素的边界矩形
            const insRect = adContainer.getBoundingClientRect();

            // 计算父级 ins 元素相对于页面左上角的位置和高度
            const insX = insRect.left;
            const insY = insRect.top;
            const insHeight = insRect.height;

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let transformData = {
              insX: insX,
              insY: insY,
              insHeight: insHeight,
              windowWidth: windowWidth,
              windowHeight: windowHeight,
            }
            this.$adClick(JSON.stringify(transformData))
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
          }
        });
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
            }
          }
        };

        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //shuju
    async getTagList () {
      try {
        const res = await this.$global.server.getTagList({ type: this.domainConfig['IAmType'] })
        console.log(res)
        if (res.code == 200) {
          let data = res.data
          this.tagList.push(...data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getWiki () {
      const params = {
        id: this.activeId
      };

      try {
        const res = await this.$global.server.getWiki(params)
        console.log(res)
        if (res.code == 200) {
          this.wikiInfo = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getWikiList () {
      const params = {
        type: this.domainConfig['IAmType'],
        pageNum: this.page,
        pageSize: 6,
      };
      if (this.activeTag) {
        params.category = this.activeTag
      }

      try {
        const res = await this.$global.server.getWikiList(params)
        console.log(res)
        if (res.code == 200) {
          let data = res.data.popularizations
          this.wikiList.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
      } catch (err) {
        console.log(err)
      }
    },
    chooseTag (tag) {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/${tag}${fullQueryString}`
    },
    chooseWiki (id, name, cate) {
      console.log(id, name);
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/${cate.replace(/\s+/g, '-')}/${id}/${name.replace(/\s+/g, '-')}${fullQueryString}`
    },
    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        this.getWikiList();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig.homeRecommand.indexOf(position) : this.adPosConfig.homeRecommandPC.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds('ads-'); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });
      console.log(adsElements);

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;  // 如果是 Vue 实例，获取它的 $el
        if (adElement) {
          observer.observe(adElement); // 开始监听广告元素是否进入可视区域
          console.log('Observing ad element:', adElement);
        }
      });
    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //ciyi

    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
